@import "../../../styles/app";

.arrow {
  color: $gray-400;
  transition: 0.3s;
  right: 0;
}

.arrowActive {
  transform: rotate(-90deg);
}

.panel {
  border: none;
  box-shadow: none;
  margin: 0;
  border-radius: 0;
  background: rgba(#000, 0.1);

  div {
    padding: 0;
  }

  ul {
    padding-left: 0;
    font-size: $font-size-small;

    li {
      padding: 3px 0;
      list-style: none;
    }

    a {
      padding: 4px 20px 4px 44px;

      &:hover,
      &:active,
      &:focus {
        font-weight: $font-weight-semi-bold;
      }
    }
  }
}
