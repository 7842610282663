// Container

.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

// Navbar

.navbar {
  border: none;
  height: $navbar-height;
  border-radius: $navbar-border-radius;
  margin-bottom: $navbar-margin-bottom;
  padding: 0.5rem 2.25rem;
}

@media (min-width: $grid-float-breakpoint) {
  .navbar-right {
    .dropdown-menu {
      left: 0;
    }
  }
}

// Nav

.nav {
  &.nav-tabs {
    .nav-item {
      a {
        color: $text-color;

        &.active {
          background-color: theme-color("danger");
          border-color: theme-color("danger");
          color: white;
        }
      }
    }
  }
}

// Progress

.progress-bar {
  @include box-shadow(none);
}

.progress-sm {
  height: 1rem;
  margin-bottom: 1rem;
}

.progress-xs {
  height: 5px;
  margin-bottom: 0.5rem;
}

// Breadcrumb

.breadcrumb {
  margin-top: -15px;
  font-size: $font-size-small;

  > .active {
    font-weight: 600;
  }

  > li + li::before {
    color: $gray-light;
  }
}

// Form
body {
  .form-control {
    font-size: $font-size-base;
    background-color: $brand-secondary;
    color: $text-color;

    @include box-shadow(none);
    @include transition(
      border-color ease-in-out 0.15s,
      background-color ease-in-out 0.15s
    );

    &:focus {
      background-color: $brand-secondary;
      color: $text-color;
      @include box-shadow(none);
    }

    &.no-border {
      border: none;
      background-color: darken($input-bg, 5%);

      &:focus {
        background-color: darken($input-bg, 7%);
      }
    }
  }

  &.light {
    .form-control {
      background-color: #fff;
      color: #000;
    }
  }
}
.input-sm {
  font-size: 13px;
}

// Buttons

.btn-inverse {
  @include button-variant(
    $btn-inverse-color,
    $btn-inverse-bg,
    $btn-inverse-border
  );
}

// Alerts

.alert {
  font-size: $font-size-small;

  .close {
    color: rgba(#000, 0.4);
  }
}

.alert-sm {
  padding: 10px 15px;

  .close {
    font-size: 20px;
    top: 0;
    right: -8px;
  }
}

// Badge

.badge {
  font-size: 12px;

  .list-group-item > & {
    margin-top: 2px;
  }
}

// Table
body {
  .table {
    color: #fff;
  }
  .table#image-mapper-table tr.input-tr td {
    border-radius: 30px;
    border: 10px solid $brand-primary;
  }

  .dropdown-arrow {
    color: $gray-400;
    transition: 0.3s;
    right: 0;
  }

  .dropdown-menu {
    border: 2px solid $input-border;
    margin-top: 0.7rem;
    background-color: $brand-secondary;
  }
  .dropdown-item {
    &:active {
      background-color: $brand-secondary;
      color: $text-color;
    }

    &:focus {
      outline: none;
    }

    a {
      display: block;
      color: $text-color;

      &:hover {
        color: $text-color;
        text-decoration: none;
        cursor: default;
      }
    }
  }

  &.light {
    .btn-success {
      color: #fff;
      background-color: #2e59a8;
      border-color: #2e59a8;
    }

    .table {
      color: #000;
    }
    .table#image-mapper-table tr.input-tr td {
      border: 10px solid #fff;
    }

    // .header-root {
    //   background-color: $navbar-bg-light-color;
    //   border-bottom: 1px solid $navbar-border-light-color;
    // }

    .dropdown-arrow {
      color: #000;
      transition: 0.3s;
      right: 0;
    }

    .dropdown-menu {
      border: 2px solid #f7f7f7;
      margin-top: 0.7rem;
      background-color: #fff;
    }

    .dropdown-item {
      color: #000;
      &:active,
      &:hover {
        background-color: #eee;
        color: #000;
      }

      a {
        color: #000 !important;

        &:hover {
          color: #000;
        }
      }
    }
  }
}
.table-no-border {
  margin-left: -$table-cell-padding;
  margin-right: -$table-cell-padding;

  > thead > tr > th,
  > thead > tr > td,
  > tbody > tr > th,
  > tbody > tr > td,
  > tfoot > tr > th,
  > tfoot > tr > td {
    border-top: 0;
  }
}

.table-sm {
  font-size: $font-size-small;

  > thead > tr > th,
  > thead > tr > td,
  > tbody > tr > th,
  > tbody > tr > td,
  > tfoot > tr > th,
  > tfoot > tr > td {
    padding: 4px;
  }

  &.table-no-border {
    margin-left: -4px;
    margin-right: -4px;
  }
}

// Code

pre {
  display: block;
  padding: 6.5px;
  margin: 0 0 (1rem / 2);
  font-size: 13px;
  line-height: 1rem;
  word-break: break-all;
  word-wrap: break-word;
  color: $pre-color;
  background-color: $gray-100;
  border: 1px solid $gray-400;
  border-radius: $border-radius;

  // Account for some code outputs that place code tags in pre tags
  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
  }
}

// List

.list-group {
  padding: 0 1.25rem;
  background: $white;

  .list-group-item {
    border: none;
    padding: 1.25rem 0;
    color: theme-color("info");
    border-top: 1px solid $gray-200;

    &:hover {
      z-index: 0;
    }

    &:first-child {
      border-top: none;
    }
  }
}

body {
  .text-body {
    color: #fff !important;
  }

  .rdt_TableHeader,
  .rdt_Table,
  .rdt_TableCell,
  .rdt_TableCol_Sortable {
    background-color: $brand-primary !important;
    color: #fff !important;
  }

  .infectedCard {
    background: #080e33;
    display: grid;
    place-items: center;
    border-radius: 20px;
  }

  &.light {
    .text-body {
      color: #000 !important;
    }

    .rdt_TableHeader,
    .rdt_Table,
    .rdt_TableCell,
    .rdt_TableCol_Sortable,
    .rdt_Pagination {
      background-color: #fff !important;
      color: #000 !important;
    }

    .infectedCard {
      background: #fff;
    }
  }
}
