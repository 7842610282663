html {
  font-weight: 100;
  font-size: 14px;
}

body {
  overflow-x: hidden;

  &.light {
    background-color: $body-bg-light;
    color: $body-color-light;
  }
}

html,
body,
.app {
  height: 100%;
}

a {
  cursor: pointer;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;
}

th {
  font-weight: 600;
}

label {
  font-weight: 600;
  margin-left: 10px;
}
.form-control {
  border-radius: 20px;
  border: 2px solid #1f225b;
  margin: 10px;
}
.btn {
  border-radius: 40px;
  padding: 4px 20px;
  margin: 10px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 441px;
  top: 0;
  z-index: 9999;
}
.inactive-svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.text-error {
  color: red;
}
.control-label {
  display: flex;
  align-items: baseline;
}
.form-control-error {
  margin-left: 15px;
}
.rounddonut {
  display: flex;
  align-items: center;
}
.donut {
  width: 305px;
  height: 305px;
  border-radius: 100%;
  position: relative;
  margin-right: 15px;
}
/* .donut h2{
    position: absolute;
    text-align: center;
    line-height: 185px;
    width: 100%;
    color: #000;
} */
.grapContainer {
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: 15px;
}
body {
  .getColorText {
    position: absolute;
    text-align: center;
    padding: 30px 20px 0 20px;
    width: 100%;
    color: #fff;
  }

  .getColorText small {
    line-height: 15px;
    display: inline-block;
  }

  .input-tr {
    background-color: #1f225b;
    border-radius: 10px;
  }

  #pagination-next-page,
  #pagination-first-page,
  #pagination-last-page,
  #pagination-previous-page {
    color: rgb(255, 255, 255);
    fill: rgb(255, 255, 255);
  }
  #pagination-next-page:disabled,
  #pagination-first-page:disabled,
  #pagination-last-page:disabled,
  #pagination-previous-page:disabled {
    color: rgba(255, 255, 255, 0.7) !important;
    fill: rgba(255, 255, 255, 0.7) !important;
  }

  &.light {
    .getColorText {
      color: #000;
    }

    .input-tr {
      background-color: #eee;
    }

    #pagination-next-page,
    #pagination-first-page,
    #pagination-last-page,
    #pagination-previous-page {
      color: rgb(0, 0, 0);
      fill: rgb(0, 0, 0);
    }
    #pagination-next-page:disabled,
    #pagination-first-page:disabled,
    #pagination-last-page:disabled,
    #pagination-previous-page:disabled {
      color: rgba(0, 0, 0, 0.7) !important;
      fill: rgba(0, 0, 0, 0.7) !important;
    }
  }
}
.donut.red {
  border: 60px solid #f31111;
}

.donut.deepgreen {
  border: 60px solid #08be62;
}

.donut.orange {
  border: 60px solid #ffe600;
}
.legendprt {
  display: flex;
  flex-direction: column;
  width: calc(100% - 320px);
}
.legentpanel {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.boxred {
  background: #f31111;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin-right: 5px;
}
.boxgreen {
  background: #08be62;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin-right: 5px;
}
.boxorange {
  background: #ffe600;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin-right: 5px;
}

.bannerListing {
  margin-top: 30px;
}
.trendWidget {
  width: 650px;
}

// Toggler
.toggle-checkbox-label {
  font-size: 11px;
}
.toggle-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.toggle-slot {
  position: relative;
  height: 20px;
  width: 70px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 50px;
  background-color: transparent;
  transition: background-color 250ms;
  margin-top: 2px;
  margin-right: 10px;
  cursor: pointer;
}
.toggle-checkbox:checked ~ .toggle-slot {
  background-color: transparent;
}
.toggle-button {
  transform: translate(55px, 4px);
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #ffeccf;
  box-shadow: inset 0px 0px 0px 0.75em #fff;
  transition: background-color 250ms, border-color 250ms,
    transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}
.toggle-checkbox:checked ~ .toggle-slot .toggle-button {
  background-color: #485367;
  box-shadow: inset 0px 0px 0px 0.75em #4285f4;
  transform: translate(4px, 4px);
}
.sun-icon {
  position: absolute;
  height: 20px;
  width: 20px;
  color: #ffbb52;
}
.sun-icon-wrapper {
  position: absolute;
  height: 20px;
  width: 20px;
  opacity: 1;
  transform: translate(0px, 2px);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}
.toggle-checkbox:checked ~ .toggle-slot .sun-icon-wrapper {
  opacity: 0;
}
.moon-icon {
  position: absolute;
  height: 20px;
  width: 20px;
  color: white;
}
.moon-icon-wrapper {
  position: absolute;
  height: 20px;
  width: 20px;
  opacity: 0;
  transform: translate(45px, 2px);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(0.26, 2.5, 0.46, 0.71);
}
.toggle-checkbox:checked ~ .toggle-slot .moon-icon-wrapper {
  opacity: 1;
}

.alert {
  padding: 20px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  margin-bottom: 15px;
}

body {
  .headerLink {
    a {
      display: flex;
      color: $text-color;
      align-items: center;
      text-decoration: none;
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        color: $text-color;
        font-weight: $font-weight-semi-bold;
      }
    }

    > a {
      position: relative;
      padding: 0 15px;
      line-height: 55px;
      justify-content: space-between;

      &:hover {
        font-weight: $font-weight-thin;
        background-color: $gray-100;
      }

      img {
        width: 25px;
        margin-right: 15px;
      }
    }
  }

  .headerLinkActive {
    background-color: $brand-select;
    border-left: 5px solid $icon-color;
  }

  .headerLinkText {
    color: #fff;
    font-weight: $font-weight-semi-bold;
  }

  .content {
    min-height: 100%;
    padding: #{$navbar-height + $content-padding-vertical} $content-padding-horizontal
      ($content-padding-vertical + 20px);
    background-color: $brand-secondary;
  }

  .camera-select-card {
    background-color: #080e33;
    border-radius: 20px;
    padding-bottom: 15px;
  }

  .widget {
    display: block;
    position: relative;
    margin-bottom: 30px;
    padding: $widget-padding-y $widget-padding-x;
    background: $widget-bg;
    border-radius: 20px;
  }

  .title {
    margin-top: 0;
    margin-bottom: 1.5rem / 2;
    color: $widget-title-color;
    @include clearfix();
  }

  .login-card {
    background-color: #0d154a;
    padding: 20px;
    margin-right: 20px;
  }

  &.light {
    .headerLink {
      a {
        display: flex;
        color: $text-color-light;
        align-items: center;
        text-decoration: none;
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
          color: $text-color;
          background-color: #fff;
        }
        &:hover span,
        &:active span,
        &:focus span {
          color: $brand-select !important;
        }
      }
    }
    > a {
      &:hover {
        font-weight: $font-weight-thin;
        color: $brand-select !important;
      }
    }

    .headerLinkActive {
      background-color: #eee !important;
    }

    .headerLinkText {
      // color: $text-color-light;
      // color: $brand-select;
      font-weight: $font-weight-semi-bold;
    }
    .headerLinkActive .headerLinkText {
      color: $brand-select !important;
    }

    .content {
      min-height: 100vh;
      background-color: $body-content-bg-light;
    }

    .camera-select-card {
      background-color: #fff;
    }

    .widget {
      background: $widget-bg-light;
    }

    .title {
      color: $widget-title-light-color;
    }

    .login-card {
      background-color: #fff;

      .text-muted {
        color: #666 !important;
      }
    }
  }
}
